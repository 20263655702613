import React, { useRef, useState } from "react"
import Photo from "./Photo"
import { useSpring, animated } from "react-spring"
import { useGesture } from "react-use-gesture"
import { useInterval, computeInnerSize } from "../../utils"

export default function ImageWall({ photos, id }) {
  return (
    <div id={id} className="my-2 overflow-hidden">
      <animated.div className="relative lg:flex justify-evenly -mx-2 lg:-mx-5 my-4 h-screen/3 lg:h-64 cursor-move">
        {[...photos]
          .slice(
            0,
            photos.length > 9 ? Math.round(photos.length / 2) : photos.length
          )
          .map(photo => (
            <Photo
              key={photo.url}
              image={photo}
              className="m-2 select-none h-full"
            />
          ))}
      </animated.div>
    </div>
  )
}
