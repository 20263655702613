// https://www.blobmaker.app/

import React, { useEffect, useState } from 'react'
import { useSpring, animated } from 'react-spring'
import { useInterval } from '../../utils'
let ifvisible
if (typeof window !== `undefined`) import('ifvisible.js').then(e => ifvisible = e)

const defaultShapes = [
  'M41,-66.7C53.2,-55.9,63.3,-44.7,70.5,-31.5C77.7,-18.2,82,-2.9,80.2,11.7C78.4,26.4,70.6,40.4,60.2,51.7C49.7,62.9,36.6,71.5,22,76.3C7.5,81.1,-8.5,82.3,-22,77.3C-35.5,72.2,-46.5,61,-56.2,49.1C-66,37.2,-74.5,24.6,-80,9.4C-85.6,-5.8,-88.1,-23.6,-83.1,-39.4C-78.1,-55.2,-65.6,-68.9,-50.5,-78.5C-35.4,-88,-17.7,-93.2,-1.7,-90.6C14.4,-88,28.7,-77.5,41,-66.7Z',
  'M42.7,-65.5C56.7,-57.5,70.3,-47.9,75.7,-34.9C81.1,-21.9,78.3,-5.5,75.2,10.2C72,26,68.4,41.1,59.5,51.8C50.6,62.5,36.3,68.7,21.5,73C6.7,77.3,-8.7,79.6,-24.2,77.3C-39.6,75,-55.2,68.2,-64.6,56.4C-73.9,44.7,-77.1,28.1,-78.8,11.8C-80.4,-4.5,-80.4,-20.6,-75.9,-36.4C-71.4,-52.2,-62.4,-67.6,-49,-75.9C-35.6,-84.2,-17.8,-85.3,-1.7,-82.7C14.4,-80,28.7,-73.5,42.7,-65.5Z',
  'M40.4,-60C54.6,-53.7,70,-46.3,78.1,-34C86.3,-21.8,87.3,-4.5,81.1,9C75,22.5,61.8,32.3,51.1,43.8C40.4,55.3,32.2,68.6,19.8,76.5C7.3,84.3,-9.5,86.8,-21.5,80.1C-33.5,73.4,-40.9,57.6,-49.7,44.6C-58.5,31.7,-68.8,21.5,-75.3,7.9C-81.8,-5.7,-84.6,-22.8,-77.6,-34.3C-70.6,-45.8,-53.7,-51.6,-39.2,-57.7C-24.6,-63.9,-12.3,-70.3,0.4,-70.9C13.1,-71.5,26.2,-66.3,40.4,-60Z',
  'M38,-57.3C51.4,-50.5,66,-43.7,74.2,-32C82.3,-20.4,84.1,-4,82.1,12C80.1,28,74.3,43.7,64.1,55.5C53.8,67.3,39.1,75.1,24.3,76.7C9.5,78.4,-5.4,73.8,-19.1,68.3C-32.8,62.8,-45.2,56.4,-53.2,46.5C-61.1,36.6,-64.6,23.2,-68.3,8.8C-71.9,-5.6,-75.7,-20.9,-70.1,-31.5C-64.5,-42.1,-49.5,-47.9,-36.2,-54.8C-23,-61.7,-11.5,-69.6,0.4,-70.3C12.3,-70.9,24.6,-64.1,38,-57.3Z',
  'M42.5,-65.9C52.7,-59.6,56.8,-43.7,64.5,-28.9C72.3,-14,83.6,-0.2,85.8,15.2C88,30.5,81.1,47.4,69.3,58.7C57.4,70,40.7,75.8,25.3,75.4C9.9,75.1,-4.2,68.6,-18.4,63.8C-32.5,59.1,-46.8,56,-55.9,47.4C-65,38.8,-69,24.6,-73.7,9C-78.4,-6.6,-83.9,-23.7,-80.2,-38.7C-76.6,-53.6,-63.8,-66.5,-48.9,-70.6C-34,-74.7,-17,-70.2,-0.4,-69.5C16.2,-68.9,32.3,-72.2,42.5,-65.9Z',
  'M43.6,-67C57.3,-59,69.8,-48.3,73.6,-35.1C77.4,-21.8,72.5,-6.1,67.8,8.1C63.2,22.2,58.8,34.7,51.6,46.7C44.3,58.7,34.2,70.2,21.2,75.5C8.3,80.8,-7.4,79.8,-22,75.4C-36.6,70.9,-50,63,-56.5,51.3C-62.9,39.6,-62.4,24.3,-63.9,9.6C-65.3,-5,-68.7,-19,-66.1,-32.5C-63.5,-46,-54.9,-59.1,-42.9,-67.9C-30.9,-76.8,-15.5,-81.4,-0.3,-81C14.9,-80.6,29.8,-75.1,43.6,-67Z',
  'M36,-59.9C44.6,-50.5,48.1,-37.1,55.1,-24.4C62,-11.8,72.3,0.1,72.1,11.3C72,22.5,61.3,33.2,51.5,44.7C41.7,56.2,32.7,68.6,20.1,75.4C7.6,82.2,-8.7,83.5,-21.2,77.4C-33.7,71.3,-42.4,57.8,-48.8,45.2C-55.2,32.6,-59.3,20.9,-65.7,6.6C-72.2,-7.6,-81,-24.3,-78.6,-38.5C-76.2,-52.7,-62.5,-64.3,-47.5,-70.7C-32.5,-77.1,-16.3,-78.3,-1.3,-76.3C13.7,-74.3,27.4,-69.2,36,-59.9Z',
  'M38.2,-57.5C48.1,-53.1,53.6,-40.1,62.4,-26.8C71.1,-13.5,83.1,0.1,83.3,13.3C83.5,26.5,71.9,39.4,59,47.3C46.2,55.1,32,58,18,62.9C3.9,67.8,-10,74.8,-22.2,72.6C-34.3,70.3,-44.5,58.9,-50.6,46.7C-56.6,34.5,-58.5,21.6,-63.3,7.3C-68.1,-6.9,-75.8,-22.5,-71.5,-33C-67.3,-43.6,-51.2,-49,-37.3,-51.6C-23.4,-54.1,-11.7,-53.8,1.2,-55.7C14.2,-57.6,28.3,-61.9,38.2,-57.5Z',
  'M37.8,-57.5C48.6,-51.9,56.7,-40.7,65.7,-28C74.7,-15.2,84.6,-0.8,83.9,12.9C83.2,26.6,72,39.6,60.1,50.6C48.2,61.5,35.8,70.5,23,70.7C10.3,70.9,-2.7,62.3,-18.5,59.4C-34.4,56.5,-52.9,59.2,-65.9,52.5C-78.9,45.8,-86.3,29.6,-88.2,13C-90.1,-3.7,-86.5,-20.8,-78.3,-34.5C-70.1,-48.3,-57.3,-58.7,-43.4,-62.9C-29.6,-67.1,-14.8,-65.1,-0.7,-64.1C13.5,-63.1,27,-63.1,37.8,-57.5Z',
  'M38.5,-51.4C53,-50.5,70,-45.1,77.9,-33.8C85.9,-22.5,84.7,-5.3,74.9,5.5C65.1,16.2,46.8,20.4,36.8,31.9C26.9,43.4,25.3,62.1,17.6,68.2C9.8,74.3,-4.1,67.8,-20.6,65.2C-37.2,62.6,-56.3,64,-61.3,55C-66.4,45.9,-57.2,26.4,-53.7,11.3C-50.3,-3.7,-52.5,-14.3,-49.2,-22.8C-45.9,-31.3,-37,-37.6,-27.9,-40.9C-18.8,-44.2,-9.4,-44.5,1.3,-46.5C12,-48.5,24,-52.2,38.5,-51.4Z',
  'M42.8,-62.5C52.8,-60.3,56.2,-43.8,58.6,-29.4C60.9,-15.1,62.1,-3,64.6,12.3C67.1,27.7,71,46.2,62.2,50.9C53.5,55.6,32.2,46.4,17.2,44.4C2.1,42.4,-6.7,47.5,-18.9,50.3C-31.1,53.2,-46.8,53.7,-59.5,47.1C-72.2,40.5,-81.8,26.8,-81.1,13.4C-80.3,-0.1,-69.1,-13.3,-58.5,-22.6C-47.9,-32,-37.8,-37.5,-28.1,-39.9C-18.4,-42.3,-9.2,-41.6,3.6,-47.2C16.4,-52.8,32.9,-64.8,42.8,-62.5Z',
  'M41.6,-70.7C50.3,-59.2,51.2,-41.5,51.1,-27.5C51.1,-13.5,50.1,-3.2,53.5,11.2C56.9,25.6,64.6,44.1,59.3,52.6C53.9,61.1,35.5,59.5,20.7,58.3C5.9,57,-5.2,56.2,-17.9,55.1C-30.7,54.1,-45.2,52.9,-52.8,45.2C-60.5,37.4,-61.4,23.2,-60.6,10.4C-59.7,-2.3,-57.1,-13.6,-51.2,-22.1C-45.3,-30.5,-36.1,-36.2,-27,-47.4C-17.9,-58.7,-9,-75.7,3.8,-81.5C16.5,-87.4,32.9,-82.1,41.6,-70.7Z',
  'M45.1,-66.4C59.2,-61.1,71.9,-49.9,73.6,-36.5C75.3,-23.1,65.9,-7.5,57,3.5C48.2,14.6,39.9,21,35,34.9C30.1,48.8,28.5,70.2,19.6,77.8C10.8,85.3,-5.3,79.2,-21.4,73.9C-37.4,68.7,-53.4,64.4,-56.7,53.2C-60.1,42,-50.9,24,-50.4,9C-49.8,-6,-57.8,-18.1,-56,-27C-54.1,-35.9,-42.3,-41.7,-31.3,-48.5C-20.3,-55.2,-10.2,-63,2.7,-67.1C15.5,-71.3,31,-71.8,45.1,-66.4Z',
  'M25.6,-30.9C39.6,-30.8,62,-34.6,64.5,-29.4C67,-24.1,49.6,-9.7,42.5,2.7C35.5,15.2,38.7,25.7,37,37.7C35.4,49.8,29,63.2,19.6,65.3C10.3,67.4,-2,58,-15.5,53.7C-29,49.3,-43.6,49.9,-52.1,43.3C-60.7,36.8,-63.1,23.1,-58.6,12.3C-54,1.5,-42.6,-6.3,-35.4,-13.8C-28.3,-21.2,-25.4,-28.1,-20.2,-32.3C-15,-36.5,-7.5,-38,-0.9,-36.7C5.8,-35.3,11.5,-31.1,25.6,-30.9Z',
  'M25,-40.2C36.5,-31.5,52.6,-31.5,58.5,-24.9C64.4,-18.3,60,-5.2,52,3.1C44.1,11.4,32.6,14.9,24.7,18.1C16.8,21.4,12.5,24.6,6.2,30.7C0,36.8,-8,45.8,-17,47.8C-25.9,49.8,-35.7,44.6,-41.3,36.6C-46.8,28.6,-48.2,17.9,-50.2,6.9C-52.1,-4,-54.8,-15.1,-55.1,-29.6C-55.4,-44.1,-53.3,-62,-43.8,-71.5C-34.2,-81,-17.1,-82.2,-5.2,-74.1C6.8,-66.1,13.5,-48.8,25,-40.2Z',
  'M31.2,-47.8C42,-41.7,53.2,-35.6,54.7,-26.6C56.2,-17.7,48,-6,47.7,8C47.4,21.9,55,38.1,51,45.9C46.9,53.8,31.2,53.2,16.8,57.6C2.4,61.9,-10.6,71.2,-24.5,72.3C-38.5,73.3,-53.3,66.2,-53.6,53.4C-53.9,40.6,-39.7,22.1,-39.6,7.1C-39.5,-7.8,-53.6,-19.4,-55.2,-29.2C-56.9,-39,-46.2,-47.1,-34.9,-53C-23.7,-58.8,-11.8,-62.5,-0.8,-61.2C10.2,-60,20.5,-53.9,31.2,-47.8Z'
]
const timing = 50000

export default function BackgroundShape({ color, style, className }) {
  const [index, setIndex] = useState(0)
  const [shapes, setShapes] = useState()
  // useInterval(() => {
  //   if (!ifvisible.now('hidden'))
  //     setIndex((index + 1))
  // }, timing)
  useEffect(() => {
    setShapes(defaultShapes.sort(() => .5 - Math.random()))
    // setIndex(1)
  }, [])
  // const { x } = useSpring({ x: index, config: { duration: timing } })
  if (!shapes) return null
  return (
    <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" className="max-w-6xl w-1/2" style={{ ...style, zIndex: -1 }}>
      <animated.path
        fill={color}
        d={shapes[index % shapes.length]}
        // d={x.interpolate({ range: [index - 1, index], output: [shapes[(index - 1) % shapes.length], shapes[index % shapes.length]] })}
        transform="translate(100 100)" />
    </svg>
  )
}
