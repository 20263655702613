import React from "react"

export default function Feature({
  children,
  style,
  Icon,
  image,
  className,
  href,
  label,
  description,
}) {
  return (
    <a
      href={href}
      target="_blank"
      style={style}
      className={`relative flex justify-center items-center cursor-default rounded-3xl bg-white px-2 py-6 h-40 lg:h-56 ${
        href && "cursor-pointer"
      } ${className}`}
    >
      <div className="h-full w-full flex flex-col justify-center items-center text-center lg:text-base">
        <strong className="text-base text-black leading-none my-4">
          {label}
        </strong>
        <span className="font-light leading-none lg:leading-tight text-sm text-black">
          {description.data.featureDescription}
        </span>
      </div>
    </a>
  )
}
