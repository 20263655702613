import React, { useEffect, useState } from "react"

export default function Help({ help, index, id, style, className }) {
  return (
    <div id={id} style={style} className="relative m-6">
      <div
        className={`absolute max-w-xs h-full lg:max-w-none w-full rounded-3xl shadow-md z-1 -mt-4 ${
          index % 3 ? "bg-yellow" : "bg-blue"
        }`}
      />
      <div
        className={`relative max-w-xs lg:max-w-none lg:ml-5 ml-2 rounded-3xl shadow-md py-3 px-4 lg:px-6 z-10 bg-white`}
      >
        <div
          className={`flex justify-start text-base font-bold leading-tight my-4`}
        >
          {help.question}
        </div>
        <div className="flex justify-start text-sm font-light leading-tight lg:text-base my-4">
          {help.answer}
        </div>
      </div>
    </div>
  )
}
