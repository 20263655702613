import React, { useRef, useState } from "react"
import Help from "./Help"
import { useSpring, animated } from "react-spring"
import { useGesture } from "react-use-gesture"
import { useInterval, computeInnerSize } from "../../utils"

export default function HelpWall({ helps, id }) {
  if (!helps.length) return null

  return (
    <>
      <div id={id} className="relative lg:flex justify-center my-5 ">
        <div className="w-full flex flex-col justify-start pt-20">
          {helps.map(
            (help, index) =>
              index % 2 == true && (
                <Help
                  key={help.attributes.id}
                  help={help.attributes}
                  index={index}
                />
              )
          )}
        </div>
        <div className="w-full flex flex-col justify-start">
          {helps.map(
            (help, index) =>
              index % 2 == false && (
                <Help key={help.id} help={help.attributes} index={index} />
              )
          )}
        </div>
      </div>
    </>
  )
}
