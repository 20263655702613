import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { FaLinkedin } from "react-icons/fa"
import { getImageBySize, localePath } from "../utils"
import Card from "./UI/Card"
import Button from "./UI/Button"

export default function Offices({ id, children, layout }) {
  const data = useStaticQuery(graphql`
    {
      offices: allStrapiOffice {
        nodes {
          city
          address
          page {
            path
            locale
            cover {
              alternativeText
              url
              ext
            }
          }
        }
      }
    }
  `)

  const { offices } = data
  return (
    <div
      id={id}
      className="flex justify-center items-center flex-wrap -mx-2 lg:-mx-5"
    >
      {offices.nodes.map(({ city, address, page }) => (
        <Card
          key={city}
          size="default"
          layout="mini"
          title={city}
          href={localePath(page)}
          header={
            <div
              className="h-40 lg:h-56 w-full bg-cover bg-center rounded-3xl bg-gray-100 hover:opacity-75 transition-opacity duration-300"
              style={{
                backgroundImage: `url(${getImageBySize(
                  page.cover[0].url,
                  "small"
                )})`,
              }}
            />
          }
          className={"rounded-3xl"}
        >
          <div className="my-1 flex justify-center">{children}</div>
        </Card>
      ))}
    </div>
  )
}
