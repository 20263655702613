import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { FaLinkedin } from "react-icons/fa"
import Button from "./UI/Button"
import { getImageBySize } from "../utils"
import Sliders from "../components/UI/Sliders"
import { location } from "../utils"

export default function Team({ id }) {
  const data = useStaticQuery(graphql`
    {
      teams: allStrapiTeam {
        nodes {
          name
          sales
          quote {
            data {
              quote
            }
          }
          photo {
            url
            ext
          }
        }
      }
    }
  `)

  const { teams } = data
  const url = `${location().origin}/`
  const employeeUrl = name =>
    `${name.toLowerCase().replace(/ /gm, "-").replace(/'/gm, "-")}`

  return (
    <Sliders id={id}>
      {teams.nodes.map(t => (
        <a
          href={t.sales ? `${url}${employeeUrl(t.name)}` : `${t.linkedin}`}
          target="_blank"
          className={`relative flex justify-center items-center`}
        >
          <div className="flex flex-col justify-center items-center text-center lg:text-base rounded-full">
            <div
              className="h-56 w-full bg-contain bg-no-repeat bg-center"
              style={{
                backgroundImage: `url(${getImageBySize(t.photo, "small")})`, //?
              }}
            />
            <strong className="text-base leading-none mb-2 mt-4">
              {t.name}
            </strong>
            <span className="font-light leading-none lg:leading-tight text-sm">
              {t.role}
            </span>
          </div>
        </a>
      ))}
    </Sliders>
  )
}
