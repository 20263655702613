import React from "react"
import { getImageBySize } from "../../utils"
import Title from "./Title"
import Text from "./Text"

export default function SegmentPhoto({ id, className, imgs }) {
  let sortedPhotos = []

  let paysages = imgs.data.filter(
    photo => photo.attributes.height <= photo.attributes.width
  )
  let portraits = imgs.data.filter(
    photo => photo.attributes.height > photo.attributes.width
  )

  for (let i = 0; i < imgs.data.length; i++) {
    if (i % 2 === 0 && paysages.length) {
      sortedPhotos.push(paysages.shift())
    } else if (portraits.length) {
      sortedPhotos.push(portraits.shift())
    }
  }

  const half = Math.ceil(sortedPhotos.length / 2)
  const first = sortedPhotos.slice(0, half)
  const second = sortedPhotos.slice(half, sortedPhotos.length)

  function PhotoImage({ img }) {
    return (
      <div
        className={`${
          img.attributes.height > img.attributes.width
            ? `lg:w-15vw w-full m-1`
            : `lg:w-30vw w-full m-1`
        }`}
      >
        <img
          className={`${
            img.attributes.height > img.attributes.width
              ? `lg:w-15vw w-full`
              : `lg:w-30vw w-full`
          }`}
          src={`${getImageBySize(img.attributes.url)}`}
        />
      </div>
    )
  }

  return (
    <div id={id} className={`relative my-3 lg:my-6 ${className}`}>
      <div className={`relative flex justify-center max-h-screen`}>
        <div
          className={`flex flex-col justify-start items-end w-full my-6 lg:my-12`}
          style={{ maxHeight: "50vh" }}
        >
          {first.map((img, index) => {
            return <PhotoImage img={img} />
          })}
        </div>
        <div
          className={`flex flex-col-reverse	justify-end items-start max-h-36 w-full -my-6 lg:-my-12`}
        >
          {second.map((img, index) => {
            return <PhotoImage img={img} />
          })}
        </div>
      </div>
    </div>
  )
}
